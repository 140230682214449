import React, { Component } from 'react';
import logo from '../../images/celtLogo.png';
import HamburgerSvg from '../svg/HamburgerSvg';
import { Link, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';

export class NavBar extends Component {
    state = {
        inpRef: React.createRef()
    }

    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll);
        window.addEventListener("click", this.handleClickOutside);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
        window.removeEventListener("click", this.handleClickOutside);
    }

    handleScroll = () => {
        if (window.scrollY >= 50) {
            const nav = document.querySelector('.navbar');
            nav.style.position = 'fixed';
            nav.style.top = '0px';
        } else {
            const nav = document.querySelector('.navbar');
            nav.style.position = 'inherit';
        }
    }

    handleClickOutside = (event) => {
        if (this.state.inpRef.current && !this.state.inpRef.current.contains(event.target) && !document.querySelector('.lbl-resp').contains(event.target)) {
            this.closeNav(this.state.inpRef);
        }
    }

    closeNav = (inpRef) => {
        if (inpRef?.current) {
            inpRef.current.checked = false;
        }
    }

    render() {
        const { contentData } = this.props;
        const { inpRef } = this.state;
        return (
            <nav className='bg-[#fff] navbar z-[990] w-full '>
                <div className='container flex justify-between items-center h-[100px]'>
                    <div className='hidden max-[1000px]:inline-block '></div>
                        <Link to='/'>
                            <img src={logo} className='max-w-[220px] h-[80px] max-[1000px]:ml-[30px]' alt="Logo"/>
                        </Link>
                    <div className='menu max-[1000px]:mt-[12px] max-[1000px]:w-[32px]'>
                        <input ref={inpRef} type='checkbox' id='onclick' className=' inp_check' />
                        <label htmlFor='onclick' className='lbl-resp'>
                            <HamburgerSvg />
                        </label>
                        <ul className='flex navlist items-center gap-[32px] max-[1000px]:gap-[26px] '>
                            <li>
                                <NavLink to="/schools" onClick={() => this.closeNav(inpRef)} className='text-[#000] opacity-[1] text-[18px] font-[500] transition duration-300 hover:text-[var(--orange)]'>
                                    {contentData?.nav_school}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink onClick={() => this.closeNav(inpRef)} to="/xaricde-oxuyanlar/" className='text-[#000] opacity-[1] text-[18px] font-[500] transition duration-300 hover:text-[var(--orange)]'>
                                    {contentData?.nav_1}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink onClick={() => this.closeNav(inpRef)} to="/ingilis-dili-kursu/" className='text-[18px] font-[500] leading-[20px] text-[#000] transition duration-300 hover:text-[var(--orange)]'>
                                    {contentData?.nav_2}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink onClick={() => this.closeNav(inpRef)} to="/imtahanlar/" className='text-[#000] text-[18px] font-[500] transition duration-300 hover:text-[var(--orange)]'>
                                    {contentData?.nav_5}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink onClick={() => this.closeNav(inpRef)} to="/xaricde-tehsil/" className='text-[#000] text-[18px] font-[500] transition duration-300 hover:text-[var(--orange)]'>
                                    {contentData?.nav_3}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink onClick={() => this.closeNav(inpRef)} to="/contactus/" className='text-[#000] text-[18px] font-[500] transition duration-300 hover:text-[var(--orange)]'>
                                    {contentData?.nav_6}
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                    <div className='max-[1000px]:hidden'></div>
                </div>
            </nav>
        )
    }
}

const mapStateToProps = (state) => ({
    contentData: state.Data.contentData
})

export default connect(mapStateToProps)(NavBar);
